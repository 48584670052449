import React from 'react'
import { graphql } from 'gatsby'
import Blog from '@components/blog/blog'
import SEO from '@components/seo'
import Layout from '@components/layout/rootLayout'
import { injectIntl } from 'react-intl'

const LocalizedSEO = injectIntl(({ intl, category }) => {
  const { messages, locale } = intl

  return (
    <>
      <SEO title={`${messages[category]}`} />
      {locale == 'ru' && <SEO title={`${messages[category]}`} />}
    </>
  )
})

const BlogCategoryIndex = props => {
  const { pageContext } = props

  const { locale, rootFolder, currentCategory } = pageContext
  return (
    <Layout locale={locale}>
      <LocalizedSEO category={currentCategory} />
      <Blog {...props} />
    </Layout>
  )
}

const Default = props => <BlogCategoryIndex {...props} />

export default Default

export const query = graphql`
  query LocalizedCategoryQuery(
    $locale: String
    $rootFolder: String
    $categoryRegex: String
  ) {
    allDirectory(
      filter: {
        relativeDirectory: { eq: $rootFolder }
        relativePath: { regex: "/^((?!(/en|/ru)).)*$/" }
      }
    ) {
      edges {
        node {
          relativePath
          relativeDirectory
        }
      }
    }
    allMdx(
      filter: {
        fields: { categoriesKey: { regex: $categoryRegex } }
        frontmatter: { lang: { eq: $locale }, tags: { nin: "invisible" } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            excerpt
            description
            cover_image {
              publicURL
              childImageSharp {
                resize(width: 1500, height: 1500) {
                  src
                }
                gatsbyImageData(
                  width: 500
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                  layout: CONSTRAINED
                )
              }
            }
          }
          fields {
            locale
            slug
            categories
          }
        }
      }
    }
  }
`
