import React from 'react'

import Nav from '@components/nav/nav'
import Entries from './entries'
import Level3Nav from '@components/nav/level3Nav'
import { BlogRoot, BlogFullscreenWrapper } from './styles'

const Blog = props => {
  const { data } = props
  return (
    <BlogRoot>
      <Nav defaultIndex={1} rootUrl='blog' />
      <BlogFullscreenWrapper>
        {data && data.allDirectory && <Level3Nav baseSlug='blog' {...props} />}
        <Entries {...props} />
      </BlogFullscreenWrapper>
    </BlogRoot>
  )
}

export default Blog
