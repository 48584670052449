import React from 'react'

import Masonry from '@components/blog/masonry'
import Card from '@components/blog/card'

export default props => {
  const { pageContext, data } = props
  return (
    <Masonry minWidth={340}>
      {data.allMdx.edges.map(({ node }, index) => {
        return <Card key={index} node={node} pageContext={pageContext} />
      })}
    </Masonry>
  )
}
