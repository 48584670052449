import React from 'react'
import styled, { css } from 'styled-components'
import { device } from '@src/style'

export const BlogRoot = styled.div`
  background-color: ${props => props.theme.blog.bg};
`
export const BlogFullscreenWrapper = styled.div`
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
  min-height: 500px;

  @media ${device.tablet} {
    min-height: 700px;
  }
  @media ${device.laptop} {
    min-height: 900px;
  }
  @media ${device.laptopL} {
    min-height: 1064px;
  }

  ${props => props.theme.widthLimiter}
`
